/*
	Default app
*/

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
	background-color: #b8a1c2;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

/* App */

.App {
	text-align: center;
}

.App__header {
	width: 100%;
	margin-top: 4rem;
}

.App_halloween {
	margin-top: 1rem;
	font-size: 2rem;
	color: #e8e8e8;
	font-weight: 700;
}

.rotate-scale-up {
	-webkit-animation: rotate-scale-up 0.65s linear both;
	animation: rotate-scale-up 0.65s linear both;
}

/* Card */
.Card {
	border-radius: 5px;
	box-shadow: 1px 2px 3px gray;
	background-color: #ebd56a;
	display: flex;
	justify-content: center;
	margin: 2rem;
	padding: 0.5rem;
}

.Card__AltColor {
	background-color: #380d0d;
}

@media screen and (min-width: 700px) {
	.Card {
		margin: 2rem 20vw;
	}
}

/* Countdown */

.countdown {
	font-size: 1.5rem;
}

/* Progress Bar */
/* border: solid #380d0d; */

.progress-bar {
	width: 100%;
	border: solid #1c1c1c 1px;
	border-radius: 3px;
	background-color: honeydew;
}

.progress-bar__fill {
	background-color: #380d0d;
	height: 1rem;
}

.vibrate-1 {
	-webkit-animation: vibrate-1 1s linear infinite both;
	animation: vibrate-1 1s linear infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-10 18:50:38
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */

@keyframes rotate-scale-up {
	0% {
		-webkit-transform: scale(1) rotateZ(0);
		transform: scale(1) rotateZ(0);
	}
	50% {
		-webkit-transform: scale(2) rotateZ(180deg);
		transform: scale(2) rotateZ(180deg);
	}
	100% {
		-webkit-transform: scale(1) rotateZ(360deg);
		transform: scale(1) rotateZ(360deg);
	}
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-10 18:54:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
	20% {
		-webkit-transform: translate(-2px, 2px);
		transform: translate(-2px, 2px);
	}
	40% {
		-webkit-transform: translate(-2px, -2px);
		transform: translate(-2px, -2px);
	}
	60% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}
	80% {
		-webkit-transform: translate(2px, -2px);
		transform: translate(2px, -2px);
	}
	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}
@keyframes vibrate-1 {
	0% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
	20% {
		-webkit-transform: translate(-2px, 2px);
		transform: translate(-2px, 2px);
	}
	40% {
		-webkit-transform: translate(-2px, -2px);
		transform: translate(-2px, -2px);
	}
	60% {
		-webkit-transform: translate(2px, 2px);
		transform: translate(2px, 2px);
	}
	80% {
		-webkit-transform: translate(2px, -2px);
		transform: translate(2px, -2px);
	}
	100% {
		-webkit-transform: translate(0);
		transform: translate(0);
	}
}
